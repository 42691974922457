/* eslint-disable react/no-string-refs */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import { Avatar, OnClickOutside, Search, colors } from '@united-talent-agency/julius-frontend-components';

import { createShortcuts, filterInputEvent, keyboard, stringifyKey } from '../../../support/keyboard';

const Header = ({ navigate, styles, user, search, noDebounce, onSearchChange }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const searchInput = useRef();
  const trigger = useRef();

  const keyboardListener = filterInputEvent(
    createShortcuts({
      [stringifyKey('/')]: (e) => {
        e.preventDefault();

        return searchInput && searchInput.focus();
      },
    })
  );

  useEffect(() => {
    keyboard.addListener(keyboardListener);

    return () => {
      keyboard.removeListener(keyboardListener);
    };
  }, []);

  const popoverItem = (label, iconClass, onClick, styles) => {
    const iconClassName = `${styles.menuIcon} fa fa-fw ${iconClass}`;

    return (
      <li className={styles.menuItem}>
        <div onClick={onClick}>
          <i className={iconClassName} aria-hidden="true" />
          {label}
        </div>
      </li>
    );
  };

  const userName = (styles, user) => {
    const signOutOnClick = () => {
      localStorage.removeItem('uta.auth');
      window.location = '/login';
    };

    const popoverBottom = (
      <Popover id="popover-positioned-bottom" positionTop="100" title="Menu" style={{ opacity: 1 }}>
        <ul className={styles.menuList}>{popoverItem('Sign out', 'fa-sign-out', signOutOnClick, styles)}</ul>
      </Popover>
    );

    return (
      <div className={styles.user}>
        <div className={styles.userContent}>
          <div className={styles.imageColumn}>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              delay={0}
              ref={trigger}
              onBlur={() => trigger.current.hide()}
              overlay={popoverBottom}
            >
              <OnClickOutside onClickOutside={() => trigger.current.hide()}>
                <div
                  className={styles.userImage}
                  style={{
                    backgroundImage: `url(${
                      user && user.personId && user.personId.profile_pic ? user.personId.profile_pic : Avatar
                    })`,
                  }}
                />
              </OnClickOutside>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    );
  };

  const searchBar = (styles, noDebounce, search, navigate) => {
    return (
      <div className={styles.search}>
        <Search
          noDebounce={noDebounce}
          value={search.query}
          onSubmit={(query) => {
            if (!query && onSearchChange) {
              onSearchChange(query);
              return;
            }
            if (navigate) {
              navigate({ ...search, query });
            }
          }}
          className={styles.searchContainer}
          inputClassName={styles.searchInput}
          onChange={(value) => {
            onSearchChange && onSearchChange(value);
          }}
          inputRef={(searchInputRef) => {
            if (searchInputRef) {
              searchInput.current = searchInputRef;

              if (firstLoad) {
                searchInput.current.focus();
                setFirstLoad(false);
              }
            }
          }}
        />
      </div>
    );
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.leftColumn} />
      <div className={styles.searchColumn}>{searchBar(styles, noDebounce, search, navigate)}</div>
      <div className={styles.userColumn}>
        <div className={styles.header}>{userName(styles, user)}</div>
      </div>
    </div>
  );
};

const withStyles = styled({
  headerContainer: {
    background: colors.contentBackground,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
    justifyContent: 'center',
  },
  search: {
    width: '100%',
  },
  searchContainer: {
    margin: '10px',
  },
  searchInput: {
    '&&': {
      border: `1px solid ${colors.border}`,
      background: colors.background,
    },
  },
  menuList: {
    listStyle: 'none',
    width: '200%',
    marginLeft: '-30px',
    marginRight: '15px',
    marginTop: 10,
  },
  menuItem: {
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
  },
  menuIcon: {
    marginRight: 10,
  },

  userContent: {
    marginLeft: 40,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  userImage: {
    borderRadius: '50%',
    height: 35,
    width: 35,
    backgroundSize: 'cover',
    cursor: 'pointer',
  },

  imageColumn: {
    display: 'flex',
    flex: 0.4,
    marginRight: 10,
    marginTop: 3,
    flexDirection: 'column',
  },
  leftColumn: {
    display: 'flex',
    flex: 0.25,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'flex-start',
  },
  searchColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  userColumn: {
    display: 'flex',
    flex: 0.2,
    flexDirection: 'column',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: 5,
  },
});

Header.propTypes = {
  navigate: PropTypes.object,
  styles: PropTypes.object,
  user: PropTypes.object,
  onSearchChange: PropTypes.func,
  search: PropTypes.object,
  noDebounce: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const withState = connect(mapStateToProps);

export default withState(withStyles(Header));
