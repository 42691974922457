import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';

import { datadogRum } from '@datadog/browser-rum';

import { colors, PatchNotes } from '@united-talent-agency/julius-frontend-components';
import { Spinner, Branding } from '@united-talent-agency/components';
import { searchContactPortal } from '@united-talent-agency/julius-frontend-store';

import * as notes from '../../notes/contacts';
import ContactTable from './contact-table/contact-table';
import receptionSymbol from '../../assets/logo/reception-symbol-200x200.png';
import Header from '../shared/header/header';

// Flag used to register what User is active in the application for DataDog.
let DATA_DOG_SET = false;

const SHOW_BANNER = process.env.REACT_APP_SHOW_BANNER;

const Contacts = ({ styles, user, contacts, relationships, dispatch }) => {
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [search, setSearch] = useState({
    query: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState();

  useEffect(() => {
    if (!datadogRum.getInitConfiguration()) {
      DATA_DOG_SET = true;
    }
  }, []);

  useEffect(() => {
    // Flag allows this to fire 1x per user-login, as desired.
    if (user && user.azure_id && !DATA_DOG_SET) {
      datadogRum.setUser({
        id: user.azure_id,
        name: `${user.last_name}, ${user.first_name}`,
        email: user.email,
      });
      datadogRum.startSessionReplayRecording();
      DATA_DOG_SET = true;
    }
  }, [user]);

  const renderContactList = () => {
    const searchResults = getSearchResults();
    const contacts = searchResults && searchResults.length > 0 ? searchResults : getRelationshipContacts();

    if (contacts && contacts.length > 0) {
      return <ContactTable contacts={contacts} refreshSearch={() => doSearch(value)} />;
    } else {
      return (
        <div className={styles.searchResults}>
          {value ? 'No Search Results Found' : 'Search United Talent Agency Clients'}
        </div>
      );
    }
  };

  const getSearchResults = () => {
    return contacts.results && contacts.results.length ? contacts.results : [];
  };

  const getRelationshipContacts = () => {
    return relationships ? relationships.map((relationship) => relationship.party).filter((party) => !!party._id) : [];
  };

  const doSearch = (value) => {
    setIsLoading(true);
    dispatch(searchContactPortal(value, ['Client'])).then(() => setIsLoading(false));
  };

  return (
    <div className={styles.container}>
      <Branding text="Reception" symbol={receptionSymbol} showBanner={SHOW_BANNER} />
      <Header
        search={search}
        noDebounce={false}
        onSearchChange={(value) => {
          setSearch({ query: value });
          setValue(value);
          doSearch(value);
        }}
      />
      {isLoading ? (
        <div className={styles.spinner}>
          <Spinner size={60} />
        </div>
      ) : (
        <div className={styles.gridContainer}>{renderContactList()}</div>
      )}
      <PatchNotes
        isOpen={isNotesOpen}
        notes={notes.notes ? notes.notes : []}
        closeModal={() => {
          setIsNotesOpen(!isNotesOpen);
        }}
      />
    </div>
  );
};

const withStyles = styled({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.background,
    fontFamily: 'aktiv-grotesk, sans-serif',
  },
  gridContainer: {
    width: '100%',
    padding: '0 10px',
    margin: '0 auto',
    alignItems: 'center',
  },
  searchResults: {
    textAlign: 'center',
    fontWeight: '700',
    fontSize: 20,
  },
  spinner: {
    marginTop: 10,
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
  },
});

Contacts.propTypes = {
  dispatch: PropTypes.func,
  styles: PropTypes.object,
  contacts: PropTypes.object,
  user: PropTypes.object,
  relationships: PropTypes.array,
};

const mapStateToProps = (state) => ({
  contacts: state.portal.contacts,
  relationships: state.profile.relationships,
  user: state.user,
});

const withState = connect(mapStateToProps);

export default withState(withStyles(Contacts));
