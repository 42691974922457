/* eslint-disable react/prop-types */
import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { helpers, styled } from 'react-free-style';
import { Helmet } from 'react-helmet';
import Notifications from 'react-notify-toast';
import { connect } from 'react-redux';

import { Login, colors } from '@united-talent-agency/julius-frontend-components';
import { PrivateRoute, LoginCallback, redirectToAzure } from '@united-talent-agency/julius-frontend-store';
import { errors, notifications } from '@united-talent-agency/components';

import ClientTeam from './containers/client-team';

import aktivGroteskLight from './assets/fonts/aktiv-grotesk-light.woff';
import aktivGroteskRegular from './assets/fonts/aktiv-grotesk-regular.woff';
import aktivGroteskBold from './assets/fonts/aktiv-grotesk-bold.woff';

import { defaultUrl, apiServerUrl } from './support/urls';

const localVersion = require('./git.json').sha;
const { NewRelease } = notifications;
const { REACT_APP_RELEASE_CHECK_INTERVAL_SECONDS, REACT_APP_RELEASE_CHECK_FORCE_RELOAD } = process.env;
const forceReload = REACT_APP_RELEASE_CHECK_FORCE_RELOAD === true.toString();

const Routes = (props) => {
  const { styles } = props;

  const LoginComponent = () => {
    return <Login defaultUrl={defaultUrl} apiServerUrl={apiServerUrl} redirectToAzure={redirectToAzure} />;
  };

  return (
    <Router>
      <div className={styles.container}>
        <Helmet>
          <title>RECEPTION</title>
        </Helmet>
        <Notifications />
        <NewRelease
          currentVersion={localVersion}
          intervalSeconds={REACT_APP_RELEASE_CHECK_INTERVAL_SECONDS}
          forceReload={forceReload}
        />
        <Switch>
          <Route exact path="/login" component={LoginComponent} />
          <Route path="/login-callback" component={() => LoginCallback(props)} />
          <PrivateRoute exact path="/" component={ClientTeam} dispatch={props.dispatch} apiServerUrl={apiServerUrl} />
          <Route path="*">{errors.NotFound}</Route>
        </Switch>
      </div>
    </Router>
  );
};

const withStyles = styled(
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: colors.background,
      fontFamily: 'aktiv-grotesk, sans-serif',
    },
  },
  {
    css: {
      '*': {
        boxSizing: 'border-box',
      },
      html: {
        backgroundColor: colors.background,
        fontFamily: 'aktiv-grotesk, sans-serif',
        minWidth: 320,
        fontSize: 15,
        lineHeight: 1.3,
      },
    },
    rules: [
      [
        '@font-face',
        {
          fontFamily: 'aktiv-grotesk',
          src: helpers.url(aktivGroteskRegular),
          fontWeight: 400,
          fontStyle: 'normal',
        },
      ],
      [
        '@font-face',
        {
          fontFamily: 'aktiv-grotesk',
          src: helpers.url(aktivGroteskBold),
          fontWeight: 700,
          fontStyle: 'normal',
        },
      ],
      [
        '@font-face',
        {
          fontFamily: 'aktiv-grotesk',
          src: helpers.url(aktivGroteskLight),
          fontWeight: 300,
          fontStyle: 'normal',
        },
      ],
    ],
  }
);

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const withState = connect(mapStateToProps);

export default withStyles(withState(Routes));
