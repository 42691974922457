import React from 'react';
import * as _ from 'lodash';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';

import { Avatar, ClientTeam } from '@united-talent-agency/julius-frontend-components';
import { profileUrl } from '../../../support/urls';

const ContactTable = ({ contacts, styles }) => {
  const sortedContacts = _.sortBy(
    contacts.map((contact) => {
      contact._name = contact.name;
      return contact;
    }),
    ['_name']
  );

  const renderPersonCard = (person, styles) => {
    const relationships = ((person || {}).internalTeam || []).sort((a, b) => {
      if (a.type === b.type) {
        return 0;
      }
      if (a.type === 'Point Agent') {
        return -1;
      }
      if (a.type === 'Agent' && b.type !== 'Point Agent') {
        return -1;
      }
      return 1;
    });

    const isClientHeadshot = !!(person && person.profile_pic);

    return (
      <div className="mx-5 mt-0 mb-2 p-0 row" key={person ? person._id : ''}>
        <div className="m-auto pr-3 shadow-sm bg-white" style={{ width: 650 }}>
          {isClientHeadshot && (
            <div className={`m-1 ${styles.headshot}`} style={{ position: 'absolute', zIndex: 1 }}>
              <div
                className={styles.headshotImage}
                style={{
                  backgroundImage: `url(${person && person.profile_pic ? person.profile_pic : Avatar})`,
                }}
              />
            </div>
          )}
          <div className={isClientHeadshot ? 'mb-0 mt-2 pb-0 pt-2 card-body' : 'mb-0 pb-0 pt-2 card-body'}>
            <h5 className={`m-0 p-0 ${isClientHeadshot ? 'pl-4' : ''}`}>
              <div className={`${styles.clientSection} mb-2 mt-1`}>
                <div className="mr-1">{person.name}</div>
                {person.department}
              </div>
            </h5>
            <hr className="m-0 p-0 pb-2" />
          </div>
          {relationships && relationships.length > 0 && (
            <div className="mt-0 pt-0 card-body">
              <div className="row">
                <div className="text-dark m-0 pt-1 pb-0 pl-3 small card-body">Client Team</div>
                <div className="m-0 ml-3 p-0" style={{ width: 600 }}>
                  <div className="m-0 p-0">
                    <div className="m-0 pb-2 pt-1 pl-2 card-body">
                      {relationships.map((relationship, index) => {
                        let { utaEmployee } = relationship;
                        utaEmployee = utaEmployee || {};

                        return (
                          <ClientTeam
                            contacts={utaEmployee.contacts}
                            name={utaEmployee.name}
                            assistants={utaEmployee.assistants}
                            id={utaEmployee._id}
                            index={index}
                            key={index}
                            relationshipType={relationship?.type?.replace('Point Agent', 'ResponsibleAgent')}
                            allRelationshipsSize={relationships.length}
                            department={utaEmployee.department}
                            profilePicUrl={utaEmployee.profile_pic}
                            baseProfileUrl={profileUrl}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="mt-2">
      {sortedContacts.map((contact) => {
        return renderPersonCard(contact, styles);
      })}
    </div>
  );
};

ContactTable.propTypes = {
  dispatch: PropTypes.func,
  contacts: PropTypes.array,
  styles: PropTypes.object,
  refreshSearch: PropTypes.func,
};

const withStyles = styled({
  table: {},
  th: {
    textTransform: 'uppercase',
  },
  nameLink: {
    color: 'black',
  },
  headshot: {
    borderRadius: 999,
    width: 45,
    height: 45,
    border: `1px solid #DEDEDE`,
  },
  headshotImage: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    borderRadius: 999,
  },
  clientSection: {
    display: 'flex',
  },
});

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const withState = connect(mapStateToProps);

export default withState(withStyles(ContactTable));
